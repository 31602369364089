<template>
  <div>
    <v-row
      style="width: 100%"
      class="mr-0 ml-0"
    >
      <v-col
        v-if="featured_article != null"
        md="8"
        sm="4"
        cols="12"
        class="pl-0"
      >
        <v-card
          class="new-post-blog"
          @click="$router.push({ name: 'article-open', params: { id: featured_article.id } })"
        >
          <v-img
            :src="featured_article.featuredImage"
            class="new-post-blog no-br"
            gradient="to top,  rgba(20, 20, 23,.9), rgba(20, 20, 23,.33)"
          >
            <div class="mt-2 ml-4">
              <v-chip class="csgo">
                CS:GO
              </v-chip>
            </div>
            <div class="news-content-frame ">
              <v-card-title class="v-avatar-group">
                <a
                  class="news-link"
                  href="#"
                >      <v-img
                  width="35"
                  height="100%"
                  :src="'flags/' + featured_article.country + '.png'"
                  class="news-flag"
                ></v-img>{{ featured_article.title }}</a>
              </v-card-title>
              <v-card-text>
                {{ featured_article.description }}
              </v-card-text>
            </div>
          </v-img>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card class="">
          <v-img
            width="100%"
            height="400"
            src="/poster/blog-recr2.png"
            gradient="to top,  rgba(20, 20, 23,.2), rgba(20, 20, 23,.1)"
          >
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="post in articles"
        :key="post.id + 'post'"
        md="4"
        cols="12"
        class="mb-2 px-2 py-2 news-card"
        @click="$router.push({ name: 'article-open', params: { id: post.id } })"
      >
        <v-card class="mb-2 px-2 py-2 news-card-blog">
          <v-img
            :src="post.featuredImage"
            height="270"
          >
            <div class="mt-2 ml-4">
              <v-chip class="csgo">
                CS:GO
              </v-chip>
            </div>
          </v-img>

          <v-list-item-subtitle class="ml-5 mt-4">
            <span class="text--primary">
              {{ post.authorNickname }}
            </span>
            - {{ post.authorRoleLabel }}  <br>
            {{ unixToFormattedTime(post.timestamp) }}
          </v-list-item-subtitle>
          <v-card-title class="blog-title">
            <div class=" mb-2">
              <a
                class="news-link"
                href="#"
              >{{ post.title }}</a>
            </div>
            <div class="post-sm-desc news-desc-text-blog">
              {{ post.description }}
            </div>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <div class="text-center">
          <infinite-loading @infinite="loadArticles">
            <div slot="spinner"></div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4967670342965100"
     crossorigin="anonymous"></script>
<script>
import { unixToFormattedTime } from '@core/utils'

import { ref } from '@vue/composition-api'
import SteamID from 'steamid'
import InfiniteLoading from 'vue-infinite-loading'
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
  mdiEmoticonHappy,
  mdiEmoticonSad,
  mdiCommentTextMultiple,
  mdiTrophy,
  mdiSteam,
  mdiAccountBoxOutline,
  mdiFileDocumentEditOutline,
  mdiChartDonut,
  mdiAccountGroup,
  mdiTwitch,
  mdiTwitter,
  mdiDiscord,
} from '@mdi/js'

export default {
  components: {
    InfiniteLoading,
    StatisticsCardWithImages,
  },
  data() {
    return {
      postPage: 0,
    }
  },
  computed: {
    articles() {
      return this.$store.getters['post/GetAllPages']
        .map(x => x.data)
        .flat(2)
        .slice(1)
    },
    featured_article() {
      return this.$store.getters['post/HasPageLoaded'](1) ? this.$store.getters['post/GetPosts'](1)?.data[0] : null
    },
  },
  methods: {
    async loadArticles($state) {
      this.postPage += 1
      if (!this.$store.getters['post/HasPageLoaded'](this.postPage)) {
        await this.$store.dispatch('post/loadPosts', { page: this.postPage })
      }

      if (this.$store.getters['post/GetCount']() == 10000000) $state.loaded()
      else $state.complete()
    },
    resetFilters() {
      this.filters = {
        country: null,
        role: null,
        esea_placement: null,
      }
    },
  },
  setup() {
    const selectedCard = ref('')
    const isDialogVisible = ref(false)
    const ratingsOptions = {
      statTitle: 'Players searching for team',
      statistics: '149',
    }

    return {
      selectedCard,
      isDialogVisible,
      ratingsOptions,
      unixToFormattedTime,

      // icons
      icons: {
        mdiChevronUp,
        mdiFileDocumentEditOutline,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
        mdiEmoticonHappy,
        mdiEmoticonSad,
        mdiCommentTextMultiple,
        mdiTrophy,
        mdiSteam,
        mdiAccountBoxOutline,
        mdiChartDonut,
        mdiAccountGroup,
        mdiTwitch,
        mdiTwitter,
        mdiDiscord,
      },
      SteamID,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';
// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}
</style>
